var App = ( function( document, window, $ ) {

	function App() {
		this.$body = $('body');
	}

	App.prototype.init = function() {
		this.initMobileMenu();
    this.initBannerParallax();
    this.initMailChimp();
    this.initGoogleMaps();
    this.initScrollCheck(50);
	};

	/**
	 * Mobile menu
	 */
	App.prototype.initMobileMenu = function() {
		var self = this;
		this.$body
			.on('click', 'header .menu-toggle', function(e) {
				e.preventDefault();
				self.$body.toggleClass('menu-open');
			});
  };
  
  /**
	 * Parallax
	 */
	App.prototype.initScrollCheck = function(threshold) {
    var self = this;
    $(window)
      .on('scroll', function() {
        self.$body.toggleClass('scrolled', $(window).scrollTop() > threshold);
      });
  }

  /**
	 * Parallax
	 */
	App.prototype.initBannerParallax = function() {
		var $container = $('.banner-container'),
			$back = $('.banner-back'),
			$front = $('.banner-front'),
			containerH = $container.height(),
			backH = $back.height(),
			moveRange = containerH - backH;

		$(window)
			.off('scroll.parallax')
			.on('scroll.parallax', function() {
				var scrolled = $(window).scrollTop(),
					ratio = scrolled/containerH;
				if (ratio > 1 || ratio < 0) { return; }
				$back.css('background-position','center ' + (ratio * moveRange) + 'px');
				$front.css('transform','translate(-50%, ' + (-50 + (ratio * 75)) + '%)');
			});
    };

    /**
	 * MailChimp
	 */
    App.prototype.initMailChimp = function() {
        var self = this;
        this.$body
        .on('click', '#mc_embed_signup #mc-embedded-subscribe', function(e) {
            e.preventDefault();
            var $this = $(this),
                email = $this.closest('#mc_embed_signup').find('#mce-EMAIL').val();
            if ( $.trim(email) === '' ) { return; }
            self.mailChimpAction(email);
        });
    };
  
    App.prototype.mailChimpAction = function(email) {
        $('#mce-success-response, #mce-error-response').slideUp();
        $.ajax({
        url: skafoldGlobal.ajaxurl,
        type: 'POST',
        dataType: 'json',
        data: {
            action: 'mailchimp_subscribe',
            email: email
        },
        success: function(response) {
            // Render result
            //console.log(response);
            if (response.error) {
                //console.log(response.error);
                $('#mce-error-response')
                .addClass('local-msg')
                .text((response.error.indexOf('already a list member') > -1)?'This email address is already subscribed':'There has been an error.')
                .slideDown();
            } else {
                if ( $.isFunction(__gaTracker)) {
                    __gaTracker('send', 'event', 'Mailing List', 'Subscribe', email);
                }
                $('#mce-success-response')
                .addClass('local-msg')
                .text('Subscribed!')
                .slideDown();
            }
        },
        complete: function() {
            //button.removeClass('loading').text('Load more');
        }
        });
    };

    /**
	 * Google Map
	 */
    App.prototype.initGoogleMaps = function() {

        var gmap, icon, marker, 
            $map = $('.google-map'),
            latlng = {},
            zoom = 15;

        if ($map.length === 0) { return; }

        icon = {
            path: 'M7.7,6c2.9-2.9,6.4-4.3,10.5-4.3S25.9,3.1,28.9,6s4.4,6.4,4.4,10.5c0,2.1-0.5,4.4-1.5,7.1s-2.3,5.2-3.7,7.5c-1.5,2.3-2.9,4.5-4.4,6.5c-1.5,2-2.7,3.6-3.7,4.8l-1.6,1.7c-0.4-0.4-0.9-1-1.6-1.8s-1.8-2.3-3.5-4.6S9.9,33.2,8.6,31S6,26.4,5,23.7s-1.6-5.1-1.6-7.2C3.4,12.4,4.8,8.9,7.7,6z M14.5,20.3c1,1,2.3,1.5,3.7,1.5c1.5,0,2.7-0.5,3.8-1.5c1.1-1,1.6-2.3,1.6-3.7s-0.5-2.7-1.6-3.7c-1.1-1-2.3-1.5-3.8-1.5c-1.5,0-2.7,0.5-3.7,1.5S13,15,13,16.5S13.5,19.2,14.5,20.3z',
            fillColor: '#765285',
            fillOpacity: 1,
            scale: 1,
            strokeColor: '#FFFFFF',
            strokeWeight: 1,
            anchor: new google.maps.Point(14, 42)
        };

        $map.each(function(i,localMap){
            localMap = $(localMap);
            localMap.height(localMap.width() * 0.667);
            latlng = {
                lat: localMap.data('lat'),
                lng: localMap.data('lng')
            };
            zoom = localMap.data('zoom');
            gmap = new google.maps.Map(localMap.get(0), {
                center: latlng,
                zoom: zoom,
                disableDefaultUI: true,
                zoomControl: true,
                fullscreenControl: true,
                styles: mapStyle
            });
            marker = new google.maps.Marker({
                position: latlng,
                map: gmap,
                icon: icon
            });
        });

        
    };

    mapStyle = [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ebe3cd"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#523735"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f1e6"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#c9b2a6"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#dcd2be"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#ae9e90"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#93817c"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#a5b076"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#447530"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f1e6"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#fdfcf8"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f8c967"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#e9bc62"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e98d58"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#db8555"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#806b63"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8f7d77"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ebe3cd"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dfd2ae"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#b9d3c2"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#92998d"
            }
          ]
        }
      ];


	return App;

} )( document, window, jQuery );

var app = new App();
app.init();


